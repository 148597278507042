import React from 'react';
import {Link} from 'gatsby'
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle, Sandcastle, Clock, ClockRange, ClockStep, ClockViewModel, ImagerySplitDirection, ScreenSpaceEventHandler,ScreenSpaceEventType, Cartesian2, Cartesian3, Transforms, Camera, SceneMode, defined, WorldPosition } from 'cesium';
import 'cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";
import { backgroundColor } from 'styled-system';
import Styles from "./cesium.module.css";

class CesiumContainer extends React.Component {
  // divRef = React.createRef();
  // viewer = null;
  // entity = null;

  // constructor(props) {
  //   super(props);
  // }




  render() {
    return (
      <div>
        <div className={Styles.canvaswraphikaku} >
          <div id="cesiumContainerdiv" className={Styles.dupoya}>
            <div id="view3D" className={Styles.view3D}>
              <div id="slider" className={Styles.slider}></div>
            </div>
            <div id="view2D" className={Styles.view2D}>
            </div>
          </div>
        {/* <div id="toolbar"></div> */}

        </div>

<div>

<div style={{width:"50%"}}>
        <div style={{display:"flex",justifyContent: "normal", width:"100%",top:"90vh"}}>
          <div className={Styles.buttonyear1rendou}>
            1985
          </div>
          <div className={Styles.buttonyear2rendou}>
            2015
          </div>
          <div className={Styles.buttonyear3rendou}>
            OFF
          </div>
        </div>
</div>


{/* 
<div style={{position:"absolute",width:"100%",backgroundColor:"gray",margin:"-5vh 0 0 0", height:"10vh",zIndex:0}}>bbb</div> */}



<div style={{width:"50%"}}>
        <div style={{display:"flex",justifyContent: "space-between"}}>
          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="originview" value="Home"/>
          </div>
          <div className={Styles.buttonshita}>
            <div className={Styles.buttononoff} id="rotate"></div>
          </div>

          <div className={Styles.buttonshita}>
            <input className={Styles.buttononoff} type="button" id="rotate" value="Rotate"/>
          </div>
          <div className={Styles.buttonshita}>
            <Link
              className={Styles.buttononoff}
              to={this.props.full_url}
              // to={"/"+this.props.no+"/kokudof"}
              kml_kml={this.props.kml_kml}
              kml_kmz={this.props.kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={this.props.no}
            >
            {this.props.full_name}
            </Link>
          </div>
          <div className={Styles.buttonshita}>
            <input type="button" id="googlemapopen"  className={Styles.buttononoff} value="Map" />
          </div>
          <div className={Styles.buttonshita}>
            <a className={Styles.buttononoff} href={this.props.kml_kmz} download>Earth</a>
          </div>
        </div>
      </div>
</div>




</div>

    );
  }






  componentDidMount() {

    // var clock = new Clock({
    //     startTime : JulianDate.fromIso8601('2010-08-30T11:56:04+08'),
    //     stopTime : JulianDate.fromIso8601('2025-08-30T11:56:04+08'),
    //     currentTime : JulianDate.fromIso8601('2017-12-30T11:56:04+08'),
    //     clockRange : ClockRange.LOOP_STOP,
    //     clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    // });

    // var extent = Rectangle.fromDegrees(122, 20, 153, 45);
    // Camera.DEFAULT_VIEW_RECTANGLE = extent;
    // Camera.DEFAULT_VIEW_FACTOR = 0;

var clockViewModel = new ClockViewModel();
var options3D = {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  baseLayerPicker: false,
  timeline : true,
  animation : false,
  fullscreenButton: false,
  homeButton: false,
  vrButton: false,
  geocoder:false,
  sceneModePicker:false,
  navigationHelpButton:false,
  infoBox : false,
  selectionIndicator : false,
  shadows : false,
  clockViewModel: clockViewModel,
};
var options2D = {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  homeButton: false,
  fullscreenButton: false,
  sceneModePicker: false,
  clockViewModel: clockViewModel,
  infoBox: false,
  geocoder: false,
  // sceneMode: SceneMode.SCENE2D,
  navigationHelpButton: false,
  animation: false,
  timeline: false,
};


/// ビューア起動(右) ///////
var view2D = new Viewer("view2D", options2D);
/// ビューア起動(左) ///////
var view3D = new Viewer("view3D", options3D);
/////// ビューア起動 ///

var masterCamera = view3D.scene.camera;
var slaveCamera = view2D.scene.camera;

view2D.scene.preRender.addEventListener(function(){
    if(view2D.scene.mode !== SceneMode.MORPHING){
      slaveCamera.setView({
          destination : masterCamera.position,
          orientation : {
          heading : masterCamera.heading,
          pitch : masterCamera.pitch ,
          roll :  masterCamera.roll
      }
      });
    }
});


// view3D.scene.preRender.addEventListener(function(){
//     if(view3D.scene.mode !== SceneMode.MORPHING){
//       masterCamera.setView({
//           destination : slaveCamera.position,
//           orientation : {
//           heading : slaveCamera.heading,
//           pitch : slaveCamera.pitch,
//           roll :  slaveCamera.roll
//       }
//       });
//     }
// });



/// セシウムのロゴを消す ///////
    view3D._cesiumWidget._creditContainer.style.display = "none";
    view2D._cesiumWidget._creditContainer.style.display = "none";
/////// セシウムのロゴを消す ///

// /// スライダー3D //////////
// var layers2 = view3D.imageryLayers;
// var chiriinMap2 = layers2.addImageryProvider(
//   new UrlTemplateImageryProvider({
//     // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
//     url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
// //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
//     credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
//   }),
// );
// chiriinMap2.splitDirection = ImagerySplitDirection.LEFT;


// var slider2 = document.getElementById("slider2");
// view3D.scene.imagerySplitPosition = slider2.offsetLeft / slider2.parentElement.offsetWidth;
// var handler2 = new ScreenSpaceEventHandler(slider2);
// var moveActive2 = false;
// function move(movement){
//   if(!moveActive2){
//     return;
//   }
// var relativeOffset2 = movement.endPosition.x;
// var splitPosition2 = (slider2.offsetLeft + relativeOffset2) / slider2.parentElement.offsetWidth;
// slider2.style.left = 50.0 * splitPosition2 + "%";



// view3D.scene.imagerySplitPosition = splitPosition2;


// }
// handler2.setInputAction(function () {
//   moveActive2 = true;
// }, ScreenSpaceEventType.LEFT_DOWN);
// handler2.setInputAction(function () {
//   moveActive2 = true;
// }, ScreenSpaceEventType.PINCH_START);
// handler2.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
// handler2.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
// handler2.setInputAction(function () {
//   moveActive2 = false;
// }, ScreenSpaceEventType.LEFT_UP);
// handler2.setInputAction(function () {
//   moveActive2 = false;
// }, ScreenSpaceEventType.PINCH_END);
// //////// スライダー ///


/// スライダー3D //////////
var layers = view3D.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap.splitDirection = ImagerySplitDirection.LEFT;

var slider = document.getElementById("slider");
view3D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;




var layers2D = view2D.imageryLayers;
var chiriinMap2D = layers2D.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap2D.splitDirection = ImagerySplitDirection.LEFT;
view2D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;







var handler = new ScreenSpaceEventHandler(slider);
var moveActive = false;

function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;
var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 50.0 * splitPosition + "%";
view3D.scene.imagerySplitPosition = splitPosition ;
view2D.scene.imagerySplitPosition = splitPosition ;
}

handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);
handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
//////// スライダー ///



// /// スライダー2D //////////
// var layers = view3D.imageryLayers;
// var chiriinMap = layers.addImageryProvider(
//   new UrlTemplateImageryProvider({
//     // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
//     url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
// //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
//     credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
//   }),
// );
// chiriinMap.splitDirection = ImagerySplitDirection.LEFT;


// var slider = document.getElementById("slider");
// view3D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;
// var handler = new ScreenSpaceEventHandler(slider);
// var moveActive = false;
// function move(movement){
//   if(!moveActive){
//     return;
//   }
// var relativeOffset = movement.endPosition.x;
// var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
// slider.style.left = 50.0 * splitPosition + "%";
// view3D.scene.imagerySplitPosition = splitPosition;
// }

// handler.setInputAction(function () {
//   moveActive = true;
// }, ScreenSpaceEventType.LEFT_DOWN);
// handler.setInputAction(function () {
//   moveActive = true;
// }, ScreenSpaceEventType.PINCH_START);
// handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
// handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
// handler.setInputAction(function () {
//   moveActive = false;
// }, ScreenSpaceEventType.LEFT_UP);
// handler.setInputAction(function () {
//   moveActive = false;
// }, ScreenSpaceEventType.PINCH_END);
// //////// スライダー ///

// /// GoogleMapを開く //////////
var win;
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = view3D.camera.positionCartographic;
  console.log(cartographic);
          var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
          var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
          var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
          win = window.open(url ,"gmap" ,'width=800,height=600');
}, false);
// ////////// GoogleMapを開く ///

/// timelineをtrueにしたまま画面から消す //////////
    // view3D.timeline.container.style.display = 'none';
    // view3D.timeline.resize();
////////// timelineをtrueにしたまま画面から消す ///

/// 地形へのライティングを使う //////////
// view3D.scene.globe.enableLighting = true;
////////// 地形へのライティングを使う ///


/// ONOFF用のタイムライン延長KML準備 ///////
var kmlaaa = '<?xml version="1.0" encoding="UTF-8" ?><kml xmlns="http://earth.google.com/kml/2.0"><Placemark><TimeSpan><begin>'+'1985'+'-10-01'+'</begin><end>'+'2035'+'-10-01'+'</end></TimeSpan><styleUrl>#PolyCol3</styleUrl><Polygon><altitudeMode>relativeToGround</altitudeMode><outerBoundaryIs><LinearRing><coordinates></coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></kml>';
var urlaaa = URL.createObjectURL(new Blob([kmlaaa]));
/////// ONOFF用のタイムライン延長KML準備 ///

/// KMLデータ準備 ///////
    var options3D = {
      camera: view3D.scene.camera,
      canvas: view3D.scene.canvas,
    };
    var options2D = {
      camera: view2D.scene.camera,
      canvas: view2D.scene.canvas,
    };
    var dataSource3D = new KmlDataSource.load(this.props.kml_kml,options3D);
    var dataSource2D = new KmlDataSource.load(this.props.kml_kml,options2D);
/////// KMLデータ準備 ///

/// KMLデータ読み込み ///////
view2D.dataSources.add(dataSource2D).show = true;
view3D.dataSources.add(dataSource3D).show = true;
view3D.zoomTo(dataSource3D
  // , new HeadingPitchRange(0, -1, 2000000)
  );
  view3D.dataSources.add(KmlDataSource.load(urlaaa,options3D));
/////// KMLデータ読み込み ///



/// 回転ボタン //////////
document.getElementById('rotate').innerHTML =
'<div className={Styles.buttononoff} type="button" id="rotate" value="Pin">Pin</div>';
var buttona = document.getElementById('rotate');
buttona.addEventListener('click', function() {
    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue == "Pin") {
        document.getElementById("rotate").value="Stop"
        view3D.clock.onTick.addEventListener(function(clock) {
          view3D.scene.camera.rotateRight(-0.005);
        });
    } else {
          document.getElementById("rotate").value="Pin"
          view3D.zoomTo(dataSource3D).then(
              function(){
              var cartographic = view3D.camera.positionCartographic;
              console.log(cartographic);
              var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
              console.log(cartographic.latitude);
              //latitude > +0.003
              var transform = Transforms.eastNorthUpToFixedFrame(center);
              view3D.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
              view3D.clock.onTick.addEventListener(function(clock) {
                view3D.scene.camera.rotateRight(0.005);
              });
              });
    }
}, false);
////////// 回転ボタン ///

/// KMLのON/OFFボタン //////////
    // document.getElementById('toolbar2').innerHTML =
    // '<div className={Styles.buttononoff}><input type="button" id="onoff" value="Off"/></div>';

    // var buttona = document.getElementById('onoff');
    // buttona.addEventListener('click', function() {
    //     let currentvalue = document.getElementById('onoff').value;
    //     // buttona state changed.
    //     if (currentvalue == "On") {
    //         // Show if not shown.
    //         document.getElementById("onoff").value="Off"
    //         if (!view3D.dataSources.contains(dataSource1)) {
    //             view3D.dataSources.add(dataSource1).show = true;
    //             // view3D.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    //         }
    //     } else {
    //         // Hide if currently shown.
    //         // if (view3D.dataSources.contains(dataSource1)) {
    //           document.getElementById("onoff").value="On"
    //           view3D.dataSources.removeAll();
    //           // view3D.dataSources.remove(dataSource1);
    //         // }
    //     }
    // }, false);
////////// KMLのON/OFFボタン ///

/// 視点を戻すボタン（PINも解除） //////////
    var buttona = document.getElementById('originview');
    buttona.addEventListener('click', function() {
                view3D.flyTo(dataSource3D, new HeadingPitchRange(0, -1, 50000));
    }, false);
////////// 視点を戻すボタン（PINも解除） ///

/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(view3D, navoptions);
///// Navigation ///

  }
 
  // componentWillUnmount() {
  //   this.view3D.destroy();
  // }







}

export default CesiumContainer

